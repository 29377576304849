import parametersRepository from '../repositories/parameters.js';

export default {
    namespaced: true,

    state: {
        parameters: []
    },

    mutations: {
        setParameters(state, parameters) {
            state.parameters = parameters;
        }
    },

    getters: {
        getParameter: (state) => (code) => {
            const parameter = state.parameters.find((p) => p.code === code);
            return parameter ? parameter.value : null;
        },

        getParameterValue: (state) => (code, value) => {
            const parameter = state.parameters.find((p) => p.code === code);
            if (parameter) {
                const item = parameter.value.find((i) => i.key == value);
                return item ? item.value : null;
            } else {
                return null;
            }
        }
    },

    actions: {
        async fetchParameters({ commit, state }, requestedCodes) {
            const codes = [];
            if (!Array.isArray(requestedCodes)) {
                return;
            }

            for (const code of requestedCodes) {
                const parameter = state.parameters.find((p) => p.code === code);
                if (!parameter) {
                    codes.push(code);
                }
            }

            if (codes.length === 0) {
                return;
            }

            try {
                const { parameters, err } = await parametersRepository.getParameters({ codes: requestedCodes });
                if (err) {
                    throw new Error(err);
                } else {
                    commit('setParameters', [...state.parameters, ...parameters]);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }
};