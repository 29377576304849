import Vue from 'vue';

import numeral from 'numeral';

Vue.filter('round', (input, fractionDigits = 0) => {
    let number = parseFloat(input);
    if (isNaN(number)) {
        number = 0;
    }

    switch (fractionDigits) {
        case 1:
            return numeral(number).format('0,0.0');
        case 2:
            return numeral(number).format('0,0.00');
        default:
            return numeral(number).format('0,0');
    }
});

Vue.filter('floor', (input) => {
    let number = parseFloat(input);
    if (isNaN(number)) {
        number = 0;
    }

    let value = Math.floor(number);

    if (value === 0 && number > 0) {
        return 1;
    } else if (value < 0) {
        return 0;
    } else {
        return value;
    }
});
