import Repository from './Repository.js';

class MessagingRepository extends Repository {
    async getConversations(query) {
        return await this.get('messaging', query);
    }

    async createConversation(body) {
        return await this.post('messaging', body);
    }

    async getMessages(groupId, query) {
        return await this.get(`messaging/${groupId}/messages`, query);
    }

    async createMessage(groupId, input) {
        const body = new FormData();

        for (const key in input) {
            const value = input[key];
            if (Array.isArray(value)) {
                value.forEach((val, index) => {
                    if (val instanceof File) {
                        body.append(key, val);
                    } else {
                        for (const v in val) {
                            body.append(`${key}[${index}][${v}]`, val[v]);
                        }
                    }
                });
            } else {
                body.append(key, value);
            }
        }
        return await this.post(`messaging/${groupId}/messages`, body, false);
    }

    async deleteMessageAttachment(attachmentId) {
        return await this.delete(`messaging/attachments/${attachmentId}`);
    }

    async setMessageSeen(groupId, messageId) {
        return await this.post(`messaging/${groupId}/messages/${messageId}/seen`);
    }

    async toggleConversationtTag(groupId) {
        return await this.post(`messaging/${groupId}/toggleConversationtTag`);
    }

    async updateMessage(groupId, messageId, body) {
        return await this.put(`messaging/${groupId}/messages/${messageId}`, body);
    }

    async deleteMessage(groupId, messageId) {
        return await this.delete(`messaging/${groupId}/messages/${messageId}`);
    }
}

export default new MessagingRepository();
