import Repository from './Repository.js';

class SOTRepository extends Repository {
    async getSOTItems(query) {
        return await this.get('sot/items', query);
    }

    async getSOTItem(itemId, query) {
        return await this.get(`sot/items/${itemId}`, query);
    }

    async createSOTItem(body) {
        return await this.post('sot/items', body);
    }

    async updateSOTItem(itemId, body) {
        return await this.post(`sot/items/${itemId}`, body);
    }

    async cloneSOTItem(itemId) {
        return await this.post(`sot/items/${itemId}/clone`);
    }

    async deleteSOTItem(itemId) {
        return await this.delete(`sot/items/${itemId}`);
    }

    async uploadSOTItemAsset(itemId, input) {
        const body = new FormData();

        for (const key in input) {
            const value = input[key];
            if (Array.isArray(value)) {
                for (const val of value) {
                    body.append(key, val);
                }
            } else {
                body.append(key, value);
            }
        }

        return await this.post(`sot/items/${itemId}/assets`, body, false);
    }

    async updateSOTItemAsset(assetId, body) {
        return await this.put(`sot/items/assets/${assetId}`, body);
    }

    async deleteSOTItemAsset(assetId) {
        return await this.delete(`sot/items/assets/${assetId}`);
    }

    async getSOTProductLines(query) {
        return await this.get('sot/productLines', query);
    }

    async getSOTPlanAlternatives(query) {
        return await this.get('sot/planAlternatives', query);
    }

    async getSOTPlanAlternative(planId, query) {
        return await this.get(`sot/planAlternatives/${planId}`, query);
    }

    async createSOTPlanAlternative(body) {
        return await this.post('sot/planAlternatives', body);
    }

    async updateSOTPlanAlternative(itemId, body) {
        return await this.post(`sot/planAlternatives/${itemId}`, body);
    }

    async deleteSOTPlanAlternative(planId) {
        return await this.delete(`sot/planAlternatives/${planId}`);
    }

    async uploadSOTPlanAlternativeAsset(itemId, input) {
        const body = new FormData();

        for (const key in input) {
            const value = input[key];
            if (Array.isArray(value)) {
                for (const val of value) {
                    body.append(key, val);
                }
            } else {
                body.append(key, value);
            }
        }

        return await this.post(`sot/planAlternatives/${itemId}/assets`, body, false);
    }

    async updateSOTPlanAlternativeAsset(assetId, body) {
        return await this.put(`sot/planAlternatives/assets/${assetId}`, body);
    }

    async deleteSOTPlanAlternativeAsset(assetId) {
        return await this.delete(`sot/planAlternatives/assets/${assetId}`);
    }
}

export default new SOTRepository();
