<template>
    <notifications class="notifications" position="top right" animation-type="velocity">
        <template v-slot:body="{ item, close }">
            <v-card class="notification-card" :class="{ error: item.type === 'error', warn: item.type === 'warn', success: item.type === 'success' }">
                <v-toolbar style="background: transparent" dark dense flat>
                    <v-toolbar-title>
                        {{ item.title }}
                    </v-toolbar-title>

                    <v-spacer />

                    <v-btn icon x-small @click="close">
                        <v-icon> fas fa-times-circle </v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-0 white--text">
                    {{ item.text }}
                </v-card-text>
            </v-card>
        </template>
    </notifications>
</template>

<script>
export default {
    name: 'NotificationsHub'
};
</script>

<style lang="scss" scoped>
.notifications {
    margin-top: 55px;

    .notification-card {
        margin: 0 5px 5px;
        font-size: 12px;
        color: #fff;

        &::v-deep .v-toolbar__content,
        .v-card__text {
            padding: 0px 15px 5px 5px;
        }

        background: #44a4fc !important;
        border-left: 5px solid #187fe7 !important;

        &.success {
            background: #68cd86 !important;
            border-left-color: #42a85f !important;
        }

        &.warn {
            background: #ffb648 !important;
            border-left-color: #f48a06 !important;
        }

        &.error {
            background: #e54d42 !important;
            border-left-color: #b82e24 !important;
        }
    }
}
</style>

