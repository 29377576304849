import Vue from 'vue';

import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, email, max, confirmed } from 'vee-validate/dist/rules';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

setInteractionMode('aggressive');

extend('required', {
    ...required,
    message: 'Ce champ est obligatoire'
});

extend('email', {
    ...email,
    message: 'Vous devez saisir une adresse email valide'
});

extend('max', {
    ...max,
    message: 'Vous avez atteint le nombre maximum de caractères'
});

extend('number', {
    validate: (value) => /^-?[\d ]*[.|,]?(\d+)?$/.test(value),
    message: 'Vous devez saisir un nombre'
});

extend('confirmed', {
    ...confirmed,
    message: 'Les mots de passe ne sont pas identiques'
});

extend('document', {
    params: ['allowedTypes'],
    validate(file, { allowedTypes }) {
        if(!Array.isArray(allowedTypes)) {
            allowedTypes = [];
        }
        return file && file.type && allowedTypes.includes(file.type);
    },
    message: 'Vous devez choisir un document valide'
});

extend('size', {
    params: ['maxSize'],
    validate(file, { maxSize }) {
        maxSize = parseInt(maxSize);
        return file.size <= maxSize;
    },
    message: 'Ce fichier est trop lourd'
});