import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import products from './products.js';
import parameters from './parameters.js';
import application from './application.js';

export default new Vuex.Store({
    modules: {
        products,
        parameters,
        application
    }
});
