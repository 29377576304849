<template>
    <v-app-bar color="white" app dense elevate-on-scroll clipped-left>
        <v-app-bar-nav-icon @click="$store.commit('application/setDisplayMobileDrawer', true)"></v-app-bar-nav-icon>

        <v-spacer />

        <UserProfile v-if="$store.state.application.isConnected" />
    </v-app-bar>
</template>

<script>
import UserProfile from './UserProfile';

export default {
    name: 'AppBarMobile',

    data: () => ({
        displayDrawer: false
    }),

    components: {
        UserProfile
    },

    computed: {
        unreadMessagesCount() {
            const count = this.$store.state.application.unreadMessagesCount;
            if (typeof count === 'number' && !isNaN(count)) {
                if (count > 99) {
                    return '+99';
                } else {
                    return count;
                }
            } else {
                return 0;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.no-padding-left::v-deep {
    .v-toolbar__content {
        padding-left: 0;
    }
}
</style>