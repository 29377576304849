import Vue from 'vue';

import vuetify from './plugins/vuetify';
import './plugins/vue-number-animation';
import './plugins/vue-notification';
import './plugins/vue-quill-editor';
import './plugins/vee-validate';
import './plugins/introjs';

import App from './App.vue';
import store from './store';
import router from './router.js';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@mdi/font/css/materialdesignicons.css';

import './scss/main.scss';

import './mixin.js';
import './filters/index.js';

store.commit('application/init');

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
