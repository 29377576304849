<template>
    <v-app :style="cssVariables">
        <v-main style="background-color: #f6f8fc">
            <template v-if="displayAppBar">
                <AppBar v-if="!$vuetify.breakpoint.mobile" />
                <AppBarMobile v-if="$vuetify.breakpoint.mobile" />
                <NavigationDrawerMobile v-if="$vuetify.breakpoint.mobile" />
            </template>

            <router-view />
        </v-main>

        <v-footer id="footer">
            <v-row no-gutters>
                <v-col v-if="$store.state.application.isConnected">
                    <a class="no-text-decoration black--text mr-4" :href="getConfig('website_url', '')" target="_blank">
                        <small> Site {{ getConfig('name', '') }} </small>
                    </a>

                    <router-link v-if="getConfig('legal_notice', '')" class="no-text-decoration black--text mr-4" to="/mentions-legales">
                        <small> Mentions légales </small>
                    </router-link>
                </v-col>

                <v-col class="text-right">
                    <small> DP2I © 2022 </small>
                </v-col>
            </v-row>
        </v-footer>

        <v-overlay :value="$store.state.application.loading" z-index="999">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>

        <NotificationsHub />

        <DebugAccounts v-if="$store.state.application.localEnv" />
    </v-app>
</template>

<script>
import AppBar from './components/appbar/AppBar.vue';
import AppBarMobile from './components/appbar/AppBarMobile.vue';
import DebugAccounts from './components/debug/DebugAccounts.vue';
import NotificationsHub from './components/NotificationsHub.vue';
import NavigationDrawerMobile from './components/appbar/NavigationDrawerMobile.vue';


export default {
    name: 'App',

    components: {
        AppBar,
        AppBarMobile,
        DebugAccounts,
        NotificationsHub,
        NavigationDrawerMobile
    },

    computed: {
        cssVariables() {
            // from https://github.com/vuejs/vue/issues/7346
            const theme = this.$vuetify.theme.themes.light;
            return {
                '--primary-color': theme.primary,
                '--success-color': theme.success
            };
        },
        displayAppBar() {
            const routes = ['/connexion' ,'/connexion/utilisateur', '/reinitialisation-mot-de-passe'];
            if (!this.getConfig('login.display_app_bar', true)) {
                if (routes.includes(this.$route.path)) {
                    return false;
                }
            }
            return true;
        }
    }
};
</script>
