import { version } from '../../package.json';
import authRepositry from '../repositories/auth.js';

export default {
    namespaced: true,

    state: {
        version,
        providerCode: 'axessiadev',
        user: null,
        config: null,

        debug: false,
        testEnv: false,
        isPreprod: true,
        localEnv: false,

        loading: false,
        isConnected: false,
        displayMobileDrawer: false,

        sales: [],
        actualities: [],
        selectedSale: null,
        unreadMessagesCount: 0,

        sessionExpiredDialog: false,
        apiRootUrl: 'http://localhost:8081'
    },

    mutations: {
        init(state) {
            state.localEnv = location.hostname === 'localhost';

            if (state.localEnv) {
                const providerCode = localStorage.getItem('application/providerCode');
                if (providerCode !== null) {
                    state.providerCode = providerCode;
                }
                state.apiRootUrl = 'http://localhost:8081';
                state.isPreprod = true;
            } else {
                const [providerCode] = location.hostname.split('.');
                state.providerCode = providerCode;
                state.apiRootUrl = `${location.origin}/api`;
                state.isPreprod = /^.*?.test-clients.axessia.net$/.test(location.hostname);
            }

            const debug = localStorage.getItem('application/debug');
            if (debug !== null) {
                state.debug = debug === '1';
            }
        },

        disconnect(state) {
            state.sales = [];
            state.actualities = [];
            state.selectedSale = null;
            state.unreadMessagesCount = 0;
            state.isConnected = false;
            state.user = null;
        },

        setLoading(state, loading) {
            state.loading = typeof loading === 'boolean' ? loading : false;
        },

        setDisplayMobileDrawer(state, display) {
            state.displayMobileDrawer = display;
        },

        setProviderCode(state, providerCode) {
            localStorage.setItem('application/providerCode', providerCode);
            state.providerCode = providerCode;
        },

        setConfig(state, config) {
            state.config = config;
        },

        setUnreadMessagesCount(state, unreadMessagesCount) {
            state.unreadMessagesCount = unreadMessagesCount;
        },

        setIsConnected(state, isConnected) {
            state.isConnected = isConnected;
        },

        setSessionExpiredDialog(state, sessionExpiredDialog) {
            state.sessionExpiredDialog = sessionExpiredDialog;
        },

        setUser(state, user) {
            state.user = user;
            if (state.user.type === 'buyer' && state.selectedSale) {
                const buyer = state.selectedSale.buyers.find((b) => b.email === state.user.email);
                state.user.name = `${buyer.name ? buyer.name : ''} ${buyer.firstname ? buyer.firstname : ''}`;
                state.user.mobile = buyer.mobile;
                state.user.phone = buyer.phone;
            }
        },

        setSales(state, sales) {
            if (Array.isArray(sales)) {
                state.sales = sales;
            }
        },

        setActualities(state, actualities) {
            state.actualities = actualities;
        },

        setSelectedSale(state, sale) {
            state.selectedSale = sale;
            if (state.user.type === 'buyer') {
                const buyer = sale.buyers.find((b) => b.email === state.user.email);
                state.user.name = `${buyer.name ? buyer.name : ''} ${buyer.firstname ? buyer.firstname : ''}`;
                state.user.mobile = buyer.mobile;
                state.user.phone = buyer.phone;
            }
            localStorage.setItem('selectedSale', sale.id);
        }
    },

    actions: {
        async reload(context) {
            const { sales, err } = await authRepositry.getMe({ full: 1 });
            if (err) {
                throw new Error('fail to reload sales');
            }

            const sale = sales.find((s) => s.id === context.state.selectedSale.id);
            context.commit('setSales', sales);
            context.commit('setSelectedSale', sale);
        }
    }
};