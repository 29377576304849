import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

import 'vuetify/dist/vuetify.min.css';
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { fr },
        current: 'fr'
    },
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        themes:
        {
            light: {
                primary: colors.blueGrey.darken1,
                'app-blue': colors.indigo.accent2,
                'app-red': colors.pink.lighten2,
                'app-green': colors.green.lighten1
            }
        }
    }
});
