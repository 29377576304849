export default function (name) {
    if (typeof name !== 'string' || name.length === 0) {
        return 'blue';
    }

    const letter = name[0].toLocaleLowerCase();

    const colors = ['purple accent-4', 'pink accent-3', 'blue',  'deep-orange', 'blue-grey', 'teal lighten-1'];

    const letterToColorMap = {};
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';

    for (let i = 0; i < alphabet.length; i++) {
        letterToColorMap[alphabet[i]] = colors[i % colors.length];
    }

    return letterToColorMap[letter] || 'blue';
}