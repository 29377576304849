<template>
    <v-dialog v-model="isOpen" width="650px" max-width="95%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Visite guidée
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4 px-4">
                <template v-if="$route.path === '/'">
                    <h1> L'entête </h1> <br>
                    <p>
                        L’en-tête, présent sur toutes les pages de l'espace, permet de facilement retourner sur la page d’accueil de l’espace et de naviguer entre les différentes rubriques.
                    </p>

                    <h2> Logo </h2> <br>
                    <p>
                        Un clic sur le logo vous permet de retourner sur la page d’accueil de votre espace. Sur un petit écran, par exemple sur un téléphone mobile, le logo est masqué (pour retourner à la page d’accueil, ouvrez la barre de navigation en cliquant sur l’icône carrée en haut à gauche et cliquez sur “Accueil”).
                    </p>

                    <h2> Vos projets d’acquisition </h2> <br>
                    <p>
                        Dans le cas où vous avez plusieurs projets d’acquisition en cours, vous pouvez naviguer entre eux à partir du menu déroulant sur fond gris foncé, listant la référence et le type de lot.
                    </p>

                    <h2> Barre de navigation </h2> <br>
                    <p>
                        La barre de navigation principale permet d’accéder aux différentes rubriques de l’espace. Sur un petit écran, par exemple sur un téléphone mobile, les liens sont accessibles à partir de l’icône carrée en haut à gauche.
                    </p>
                    <ul>
                        <li>
                            Appels de fond
                            <ul>
                                <li>
                                    Le détail des montants appelés, l'échéancier prévisionnel des appels de fonds à venir, les règlements et les documents relatifs aux appels de fonds
                                </li>
                            </ul>
                        </li>
                        <li>
                            Suivi de chantier
                            <ul>
                                <li>
                                    Galerie de visuels et vidéos éventuelles relatifs à l'avancement du chantier du programme
                                </li>
                            </ul>
                        </li>
                        <li>
                            Documents
                            <ul>
                                <li>
                                    Toute la documentation relative à votre acquisition
                                </li>
                            </ul>
                        </li>
                        <li>
                            Etapes
                            <ul>
                                <li>
                                    Suivi du détail des différentes étapes relatives à votre acquisition et les dates clés par étape
                                </li>
                            </ul>
                        </li>
                        <li>
                            Messages
                            <ul>
                                <li>
                                    Messagerie vous permettant de contacter un interlocuteur du promoteur et de consulter tous les précédents échanges
                                </li>
                            </ul>
                        </li>
                        <li>
                            Actualités
                            <ul>
                                <li>
                                    Toutes les actualités que le promoteur souhaite partager avec vous, relatives à votre acquisition ou au programme par exemple
                                </li>
                            </ul>
                        </li>
                    </ul> <br>

                    <h2> Mon compte </h2> <br>
                    <p>
                        Un clic sur le nom associé à votre compte permet d’accéder aux fonctionnalités suivantes :
                    </p>
                    <ul>
                        <li>
                            Visite guidée
                            <ul>
                                <li>
                                    “Aide” : Présentation des fonctionnalités de la page
                                </li>
                            </ul>
                        </li>
                        <li>
                            Déconnexion
                            <ul>
                                <li>
                                    Déconnexion de votre espace (retour sur la page de connexion)
                                </li>
                            </ul>
                        </li>
                    </ul> <br>

                    <h1> Votre programme </h1> <br>

                    <h2> Diaporama de visuels </h2> <br>
                    <p>
                        Le diaporama de visuels comprend les images relatives au programme, hors les photos liées à l’avancement du chantier (accessibles dans la rubrique “Suivi de chantier”). Les flèches “précédent” et “suivant” superposées sur les visuels vous permettent de consulter l’ensemble des images.
                    </p>

                    <h2> Résumé de votre acquisition </h2> <br>
                    <p>
                        Sous le diaporama de visuels du programme, vous trouvez un résumé de votre acquisition : La référence et la typologie des lots, le nom du programme et la date de livraison prévisionnelle.
                    </p>

                    <h1> Interlocuteurs </h1> <br>
                    <p>
                        Dans la zone “Mes interlocuteurs” vous visualisez les intervenants du promoteur. Un clic sur un intervenant ou sur l’icône "dialogue" vous amène à la messagerie vous permettant d’envoyer un message au destinataire de votre choix.
                    </p>

                    <h1> Prochaine étape </h1> <br>
                    <p>
                        Le bloc “Prochaine étape” vous permet d’un coup d'œil de suivre l’avancement de votre projet d’acquisition, de la signature du contrat à la livraison. <br>
                        Pour plus de détails sur chacune des étapes, cliquez sur le lien “Toutes les étapes”.
                    </p>

                    <h1> Appels de fond </h1> <br>
                    <p>
                        Le bloc “Appels de fond” affiche un résumé de l’avancement des appels de fond et de vos règlements :
                    </p>
                    <ul>
                        <li> Le prix total de l’acquisition </li>
                        <li> Le montant total appelé et le % appelé calculés à partir du prix total </li>
                        <li> Le montant total réglé et le % réglé calculés à partir du montant déjà appelé </li>
                        <li> Rappel du dernier appel et du dernier règlement </li>
                    </ul> <br>
                    <p>
                        Pour consulter les détails de tous les appels de fonds et de vos règlements, cliquez sur le lien “Voir tous mes appels de fonds”.
                    </p>

                    <h1> Documents </h1> <br>
                    <p>
                        Le bloc “Documents” vous donne accès à votre contrat de réservation et à d’autres documents les plus récemment ajoutés à votre projet d’acquisition. En haut du bloc, vous visualisez le nombre total de documents disponibles dans votre espace.
                    </p>
                    <p>
                        Pour consulter l’ensemble de ces documents, cliquez sur le lien “Voir tous mes documents”.
                    </p>

                    <h1> Actualités </h1> <br>
                    <p>
                        La zone “Actualités” permet de consulter toutes les actualités que le promoteur souhaite partager avec vous. 
                    </p>
                    <p>
                        Dans le cas où une actualité contient plusieurs visuels ou vidéos éventuelles, ils peuvent être consultés un par un en cliquant sur les flèches superposées sur les images. Dans le cas où plusieurs actualités ont été publiées, elles peuvent être consultées une par une à partir des flèches ou des icônes rondes accessibles sous le texte.
                    </p>
                    <p>
                        A l'arrivée sur la page, seul un extrait du début du texte est affiché. Le lien “Voir plus” sous cet extrait vous permet de consulter les visuels en plus grande taille et de lire le texte complet.
                    </p>
                    <p>
                        Dans le cas où vous avez plusieurs projets d’acquisition sur plusieurs programmes différents, les actualités de la page d'accueil comprennent les actualités du programme lié à l’acquisition en cours de consultation. Le menu accès rapide en haut de page, sur fond gris foncé, vous permet de consulter l’ensemble de vos acquisitions : lors du changement du projet, les actualités de la page d’accueil se mettent à jour en fonction du programme. En revanche, la rubrique “Actualités” accessible par le menu principal vous permet de consulter toutes les actualités accessibles dans votre espace, tous les programmes confondus.
                    </p>
                </template>

                <template v-if="$route.path === '/appels-de-fonds'">
                    <p>
                        La rubrique “Appels de fonds” vous permet de consulter, dans l’ordre chronologique, les appels effectués, les règlements versés et les appels à venir.
                    </p>
                    <p>
                        La page consiste en un résumé de votre acquisition, suivi des interlocuteurs du promoteur (zone identique au bloc de la page d’accueil), d’une synthèse des montants relatifs aux appels de fonds et d’une ligne du temps détaillée contenant les dates clés, les différents stades qui déclenchent les appels de fonds et les montants liés.
                    </p>

                    <h2> Synthèse des appels et des règlements </h2> <br>
                    <p>
                        En haut de page, une synthèse des montants vous affiche le pourcentage et le montant déjà appelé (calculés à partir du prix total de l’acquisition), le pourcentage et le montant réglé (calculés à partir du montant déjà appelé), et le % et le montant des règlements restant à faire (calculés à partir du prix total de l’acquisition).
                    </p>

                    <h2> La ligne du temps </h2> <br>
                    <p>
                        Sous la synthèse des appels et des règlements, la ligne du temps présente d’un côté le détail des appels déjà effectués et le planning prévisionnel des appels à venir, et de l’autre les règlements effectués.
                    </p>
                    <p>
                        Les courriers des appels de fonds sont accessibles à partir des pictogrammes “pdf” affichés au niveau des stades respectifs dans la ligne du temps.
                    </p>
                </template>

                <template v-if="$route.path === '/suivi-de-chantier'">
                    <p>
                        La rubrique “Suivi de chantier” vous permet de consulter une galerie de photos et vidéos éventuelles représentant l’avancement du chantier du programme.
                    </p>
                    <p>
                        Les images et les vidéos sont classées par mois, du plus récent au plus ancien. Les mois sont cliquables et permettent de visualiser les éléments détaillés ajoutés pendant cette période. Un clic sur une vignette vous permet de visualiser l’élément en plus grande taille.
                    </p>
                </template>

                <template v-if="$route.path === '/documents'">
                    <p>
                        La rubrique “Documents” vous permet de consulter toute la documentation relative à votre projet d’acquisition, notamment le contrat de réservation, les plans de vente, les appels de fonds et les justificatifs.
                    </p>
                    <p>
                        Les documents sont organisés par catégorie. Au niveau de chaque bloc, le nombre total de documents disponibles dans cette catégorie est affiché. En cliquant sur un bloc, les documents relatifs sont affichés, classés du plus récent au plus ancien.
                    </p>
                    <p>
                        Les documents peuvent être téléchargés ou visualisés dans un nouvel onglet du navigateur à partir des pictogrammes respectifs sous les noms des documents.
                    </p>
                </template>

                <template v-if="$route.path === '/etapes'">
                    <p>
                        La rubrique "Étapes" vous permet de suivre les différentes étapes de votre projet d’acquisition et les dates clés relatives :
                    </p>

                    <ul>
                        <li> Signature contrat </li>
                        <li> Délai SRU </li>
                        <li> Financement </li>
                        <li> Acte authentique </li>
                        <li> Appels de fonds </li>
                        <li> Livraison </li>
                    </ul> <br>

                    <p>
                        Lorsqu’une étape est atteinte, l’en-tête de du bloc correspondant devient vert et le projet passe à l’étape suivante. L’en-tête de l’étape en cours s'affiche en bleu, et ceux des étapes non encore atteintes en gris.
                    </p>
                    <p>
                        Par exemple, lorsque le contrat de réservation a été signé par toutes les parties, le code couleur vert vous indique que l’étape a été complétée et le projet passe à l’étape suivante, “Délai SRU”. Ensuite, lorsque la date limite de rétractation est atteinte, le projet passe à l’étape “Financement”.
                    </p>
                </template>

                <template v-if="$route.path === '/messages'">
                    <template v-if="$store.state.application.user.type === 'buyer'">
                        <p>
                            La rubrique “Messages” vous permet de contacter un interlocuteur du promoteur et de consulter tous les précédents échanges.
                        </p>
                        <p>
                            Les discussions existantes sont classées de la plus récente à la plus ancienne dans la liste des discussions. Les messages peuvent être consultés en cliquant sur les noms des interlocuteurs.
                        </p>

                        <h2> Envoyer un message </h2> <br>
                        <p>
                            Pour commencer une discussion avec un nouvel interlocuteur, cliquez sur le pictogramme “Plus” + et appuyez sur un intervenant.
                        </p>
                        <p>
                            Notez que les interlocuteurs avec lesquels vous avez déjà échangé préalablement ne sont plus listés parmi les destinataires : Toute discussion existante peut être consultée et continuée en cliquant sur le nom de l’interlocuteur dans la liste des discussions.
                        </p>
                        <p>
                            Saisissez votre message dans la zone “Envoyer un message” (pour toute nouvelle discussion) ou “Répondre” (pour toute discussion existante) et cliquez sur la flèche “Envoyer” à proximité de la zone de saisie pour valider.
                        </p>

                        <h2> Réception d’un message </h2> <br>
                        <p>
                            Lorsqu’un interlocuteur du promoteur vous adresse un message, vous recevez un avertissement par e-mail. Pour consulter ce message et pour y répondre, cliquez sur le nom de l’interlocuteur dans la liste des discussions.
                        </p>
                        <p>
                            Un indicateur rouge s’affiche au niveau de la discussion lorsqu’il y a des messages non encore lus. Cet indicateur disparaît automatiquement quelques secondes après l’ouverture du message.
                        </p>
                    </template>
                    <template v-if="$store.state.application.user.type === 'user'">
                        <p>
                            La rubrique "Messages" regroupe l’ensemble des discussions initiées par les acquéreurs à partir de leur espace réservé. Un acquéreur peut prendre contact avec chacun des interlocuteurs publiés dans son espace. Une nouvelle discussion peut donc être initiée uniquement par les acquéreurs : L’interface admin ne permet pas de prendre contact avec un acquéreur sans échanges précédents.
                        </p>
                        <p>
                            Les discussions sont classées de la plus récente à la plus ancienne dans la liste des discussions. Les messages peuvent être consultés en cliquant sur les noms des acquéreurs.
                        </p>

                        <h2> Réception d’un message </h2> <br>
                        <p>
                            Lorsqu’un acquéreur vous adresse un message, vous recevez un avertissement par e-mail. Pour consulter ce message et pour y répondre, cliquez sur le nom de l’acquéreur dans la liste des discussions.
                        </p>
                        <p>
                            Un indicateur rouge s’affiche au niveau de la discussion lorsqu’il y a des messages non encore lus. Cet indicateur disparaît automatiquement quelques secondes après l’ouverture de la discussion.
                        </p>

                        <h2> Envoyer un message </h2> <br>
                        <p>
                            Pour répondre à un nouveau message, ou pour continuer une discussion avec un acquéreur avec qui vous avez échangé auparavant, cliquez sur son nom dans la liste des discussions, saisissez votre message dans la zone “Répondre” et cliquez sur la flèche “Envoyer” à proximité de la zone de saisie pour valider.
                        </p>
                        <p>
                            L’acquéreur est ensuite averti par e-mail de l’arrivée de ce nouveau message et peut vous répondre à partir de son espace réservé.
                        </p>
                    </template>
                </template>

                <template v-if="$route.path === '/actualites'">
                    <template v-if="$store.state.application.user.type === 'buyer'">
                        <p>
                            La rubrique “Actualités” vous permet de consulter toutes les actualités accessibles dans votre espace (dans le cas où vous avez plusieurs projets d’acquisition sur plusieurs programmes, les actualités concernent tous les programmes confondus indépendamment du projet d’acquisition sélectionné en haut de page), de la plus récente à la plus ancienne.
                        </p>
                        <p>
                            Le mode de fonctionnement de ces blocs est identique à la page d’accueil : Les visuels et les vidéos éventuelles sont consultables à partir des flèches superposées sur les images et les visuels sont affichés en plus grande taille et les textes sont consultables au complet à partir des liens “Voir plus”.
                        </p>
                    </template>
                    <template v-if="$store.state.application.user.type === 'user'">
                        <p>
                            La rubrique “Actualités” vous permet de gérer les actualités publiées dans l’espace client. Elles peuvent être destinées à l’ensemble des acquéreurs, à une sélection d’acquéreurs, ou aux acquéreurs ayant un projet d’acquisition au sein d’un programme donné.
                        </p>

                        <h2> Outils de navigation </h2> <br>
                        <p>
                            En haut de page, deux outils de navigation sont mis à disposition pour faciliter la consultation des actualités :
                        </p>
                        <ul>
                            <li>
                                Tri par différents critères, par exemple les dates de début ou de fin de diffusion. Possibilité de choisir ordre croissant ou décroissant en cliquant sur l’icône à côté de la zone
                            </li>
                            <li>
                                Pagination : Dans le cas où la liste contient un grand nombre d’actualités, la pagination permet de passer d’une page à l’autre (première, dernière, précédente, suivante, ou en cliquant directement sur un numéro de page)
                            </li>
                        </ul> <br>

                        <h2> Recherche d’une actualité </h2> <br>
                        <p>
                            En haut de page, deux outils de recherche sont mis à disposition pour faciliter les recherches :
                        </p>
                        <ul>
                            <li>
                                Recherche rapide par champ de saisie libre : Permet d’accéder aux actualités en saisissant tout ou une partie du titre de l’actualité
                            </li>
                            <li>
                                Filtre rapide “Actualités diffusées seulement” : Permet de limiter la sélection des actualités uniquement à celles publiées au moment actuel
                            </li>
                        </ul> <br>

                        <h2> Listing </h2> <br>
                        <p>
                            Chaque bloc “Actualité” contient un aperçu des données relatives et permet d’accéder à la consultation, modification ou suppression de l’actualité.
                        </p>
                        <p>
                            Dans le cas où une actualité contient plusieurs visuels ou vidéos éventuelles, ils peuvent être consultés un par un en cliquant sur les flèches superposées sur les images.
                        </p>
                        <p>
                            Par défaut, seul un extrait du début du texte est affiché. Le lien “Voir plus” sous cet extrait vous permet de consulter les visuels et les vidéos en plus grande taille et de lire le texte complet.
                        </p>
                        <p>
                            Sous l’extrait du texte, un pictogramme vert “coche” signifie que l’actualité est publiée au moment actuel, et au contraire, un pictogramme gris “croix” signifie qu’elle ne l’est pas. A côté de ces icônes vous trouvez le contexte dans lequel l’actualité est diffusée :
                        </p>
                        <ul>
                            <li>
                                Générique = L’actualité est publiée pour l’ensemble des acquéreurs
                            </li>
                            <li>
                                Programme(s) = L’actualité est visible pour les acquéreurs ayant un projet d'acquisition au sein d’un ou plusieurs programmes donnés
                            </li>
                            <li>
                                Groupe(s) de programmes = L’actualité est visible pour les acquéreurs ayant un projet d'acquisition au sein d’un ou plusieurs groupes de programmes. Les groupes sont définis dans le backoffice Axessia
                            </li>
                            <li>
                                Acquéreur(s) = L’actualité est publiée pour un ou plusieurs acquéreurs donnés
                            </li>
                        </ul> <br>
                        <p>
                            Les icônes respectives “crayon” et “poubelle” en bas de chaque actualité permettent de modifier ou de supprimer les données.
                        </p>

                        <h1> Ajouter une actualité </h1> <br>
                        <p>
                            Pour ajouter une nouvelle actualité, cliquez sur le lien “Ajouter” en haut de page.
                        </p>
                        <p>
                            Lors de la première étape, vous êtes amené(e) à renseigner le titre et le contenu textuel de l’actualité. Quelques outils de formatage vous permettent d’agir sur la présentation du texte, par exemple en ajoutant des effets tels que gras ou italique.
                        </p>
                        <p>
                            La deuxième étape vous permet d’ajuster, en cas de besoin, le titre et le contenu textuel, et surtout d’agir sur les conditions de publication et sur les visuels et les vidéos associés.
                        </p>

                        <h2> Modalités de diffusion </h2> <br>
                        <p>
                            Les outils suivants vous permettent d’agir sur les conditions de publication des actualités :
                        </p>

                        <ul>
                            <li>
                                La case “Publier” permet d’activer ou de désactiver la diffusion de l’actualité. Lorsque la case est désactivée, l’actualité n’est jamais publiée, même si la période de diffusion était valide
                            </li>
                            <li>
                                Dates de début et de fin : Permet d’agir sur la période de publication de l’actualité. La publication au sein de cette période nécessite également l’activation de la case “Publier”. Lorsque les dates ne sont pas renseignées, l’actualité est publiée tant que la case “Publier” est active
                            </li>
                            <li>
                                Contexte :
                                <ul>
                                    <li>
                                        Générique = L’actualité est publiée pour l’ensemble des acquéreurs
                                    </li>
                                    <li>
                                        Programme(s) = L’actualité est visible pour les acquéreurs ayant un projet d'acquisition au sein d’un ou plusieurs programmes donnés. Lorsque cette option est sélectionnée, un champ complémentaire vous permet de sélectionner / désélectionner les programmes de votre choix
                                    </li>
                                    <li>
                                        Groupe(s) de programmes = L’actualité est visible pour les acquéreurs ayant un projet d'acquisition au sein d’un ou plusieurs groupes de programmes. Les groupes sont définis dans le backoffice Axessia. Lorsque cette option est sélectionnée, un champ complémentaire vous permet de sélectionner / désélectionner les groupes de votre choix
                                    </li>
                                    <li>
                                        Acquéreur(s) = L’actualité est publiée pour un ou plusieurs acquéreurs donnés. Lorsque cette option est sélectionnée, un champ complémentaire vous permet de sélectionner / désélectionner les acquéreurs de votre choix. La sélection se fait parmi les acquéreurs pour lesquels l’espace client a été activé
                                    </li>
                                </ul>
                            </li>
                        </ul> <br>

                        <p>
                            Pour accéder plus rapidement aux programmes, groupes des programmes ou aux acquéreurs, une zone de saisie associée aux champs respectifs vous permet de renseigner tout ou une partie du nom du programme / groupe / acquéreur. Un premier clic sélectionne l’élément de votre choix, un deuxième le désélectionne. L’icône “croix” à droite des champs permet de supprimer tous les éléments déjà sélectionnés.
                        </p>

                        <h2> Ajout d’une image </h2> <br>
                        <p>
                            Pour ajouter un visuel à une actualité, cliquez sur l’icône “Plus” +, saisissez un titre (facultatif, non publié), et choisissez le type “Fichier”. Cliquez ensuite sur le champ “Fichier”, recherchez l’image de votre choix sur votre poste et validez à l’aide du bouton “Ajouter”.
                        </p>

                        <h2> Ajout d’une vidéo </h2> <br>
                        <p>
                            Pour ajouter une vidéo à une actualité, cliquez sur l’icône “Plus” +, saisissez un titre (facultatif, non publié), et choisissez le type “Url vidéo”. Saisissez ensuite l’URL de votre vidéo dans le champ de saisie et validez à l’aide du bouton “Ajouter”.
                        </p>
                        <p>
                            Il n’est pas possible de télécharger de vidéo à partir de votre poste, vous devez posséder un lien vers le fichier vidéo déjà enregistré en ligne, par exemple sur Youtube.
                        </p>

                        <h2> Suppression d’une image ou d’une vidéo </h2> <br>
                        <p>
                            Sous chaque visuel ou vidéo déjà enregistré, vous trouvez un pictogramme “poubelle” permettant de supprimer définitivement l’élément en question. Vous êtes encore amené(e) à confirmer la suppression pour éviter toute mauvaise manipulation.
                        </p>

                        <h1> Modification d’une actualité </h1> <br>
                        <p>
                            Pour modifier une actualité existante, cliquez sur l’icône “crayon” sur la liste des actualités. La fenêtre vous permettant de modifier les informations est identique à celle affichée lors de la création d’une nouvelle actualité.
                        </p>

                        <h1> Suppression d’une actualité </h1> <br>
                        <p>
                            Avant de procéder à la suppression définitive d’une actualité, posez-vous la question s’il serait préférable de désactiver sa publication, ce qui vous permettra de revenir sur les informations saisies et, en cas de besoin, d’activer l’actualité de nouveau ultérieurement.
                        </p>
                        <p>
                            Dans le cas où vous souhaitez procéder à la suppression définitive d’une actualité, y compris les images et les vidéos associées, cliquez sur l'icône “poubelle” sur la liste des actualités. Vous êtes encore amené(e) à confirmer la suppression pour éviter toute mauvaise manipulation.
                        </p>
                    </template>
                </template>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'GuidedTourDialog',

    data: () => ({
        isOpen: false
    })
};
</script>
