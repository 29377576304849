<template>
    <v-navigation-drawer v-model="isOpen" temporary app>
        <v-list v-if="$store.state.application.isConnected">
            <v-list-item>
                <v-list-item-content>
                    <SalesMenu />
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-divider />

            <v-list-item to="/">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    Accueil
                </v-list-item-title>
            </v-list-item>

            <v-list-item to="/etapes" v-if="getConfig('steps.userTypes', []).includes($store.state.application.user.type)">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    Étapes
                </v-list-item-title>
            </v-list-item>

            <v-list-item to="/documents" v-if="getConfig('documents.userTypes', []).includes($store.state.application.user.type)">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    Documents
                </v-list-item-title>
            </v-list-item>

            <v-list-item :to="getConfig('call_for_funds.labels.url')" v-if="getConfig('call_for_funds.userTypes', []).includes($store.state.application.user.type)">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    {{ getConfig('call_for_funds.labels.app_bar_title') }}
                </v-list-item-title>
            </v-list-item>

            <v-list-item to="/suivi-de-chantier" v-if="getConfig('progress_on_site.userTypes', []).includes($store.state.application.user.type)">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    Suivi de chantier
                </v-list-item-title>
            </v-list-item>

            <!-- <v-list-item to="/tma" v-if="getConfig('tma.userTypes', []).includes($store.state.application.user.type)">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    Choix & Options
                </v-list-item-title>
            </v-list-item> -->

            <v-list-item to="/messages">
                <v-badge color="app-red" :content="unreadMessagesCount" :value="unreadMessagesCount" overlap v-if="getConfig('messages.userTypes', []).includes($store.state.application.user.type)">
                    <v-list-item-title class="text-none grey--text text--darken-1 mr-4">
                        Messages
                    </v-list-item-title>
                </v-badge>
            </v-list-item>

            <v-list-item to="/actualites">
                <v-list-item-title class="text-none grey--text text--darken-1">
                    Actualités
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import SalesMenu from './SalesMenu';

export default {
    name: 'NavigationDrawerMobile',

    data: () => ({
        displayDrawer: false
    }),

    components: {
        SalesMenu
    },

    computed: {
        isOpen: {
            get() {
                return this.$store.state.application.displayMobileDrawer;
            },

            set(value) {
                this.$store.commit('application/setDisplayMobileDrawer', value);
            }
        },

        unreadMessagesCount() {
            const count = this.$store.state.application.unreadMessagesCount;
            if (typeof count === 'number' && !isNaN(count)) {
                if (count > 99) {
                    return '+99';
                } else {
                    return count;
                }
            } else {
                return 0;
            }
        }
    }
};
</script>
