import Repository from './Repository.js';

class DebugRepository extends Repository {
    async getBuyerAccounts(query) {
        return await this.get('debug/accounts/buyers', query);
    }

    async getUserAccounts(query) {
        return await this.get('debug/accounts/users', query);
    }

    async createSession(body) {
        return await this.post('debug/accounts', body);
    }
}

export default new DebugRepository();
