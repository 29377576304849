import store from '../store';
import Repository from './Repository.js';

class AuthRepository extends Repository {
    async login(body) {
        return await this.post('auth/login', body);
    }

    async disconnect() {
        return await this.get('auth/disconnect');
    }

    async getMe(query = {}) {
        query.providerCode = store.state.application.providerCode;
        return await this.get('auth/me', query);
    }

    async forgotPassword(body) {
        return await this.post('auth/forgotPassword', body);
    }

    async resetPassword(body) {
        return await this.post('auth/resetPassword', body);
    }

    async checkToken(query) {
        return await this.get('auth/checkToken', query);
    }
}

export default new AuthRepository();
