import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

Vue.use(VueQuillEditor, {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': ['', 'center', 'right'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }, { 'size': [] }]
        ]
    },
    placeholder: 'Saisissez votre texte formatté ...'
});
