<template>
    <v-app-bar class="no-padding-left" color="white" app dense elevate-on-scroll clipped-left>
        <v-toolbar-items>
            <router-link :to="logoPath">
                <v-img class="ml-auto" :src="getConfig('logo_url', '')" max-width="200" contain height="50" />
            </router-link>
        </v-toolbar-items>

        <v-spacer />

        <div v-if="$store.state.application.isConnected">
            <SalesMenu />

            <v-btn class="text-none grey--text text--darken-1" :to="getConfig('call_for_funds.labels.url')" text dark v-if="getConfig('call_for_funds.userTypes', []).includes($store.state.application.user.type)">
                {{ getConfig('call_for_funds.labels.app_bar_title') }}
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1" to="/suivi-de-chantier" text dark v-if="getConfig('progress_on_site.userTypes', []).includes($store.state.application.user.type)">
                Suivi de chantier
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1" to="/documents" text dark v-if="getConfig('documents.userTypes', []).includes($store.state.application.user.type)">
                Documents
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1" to="/etapes" text dark v-if="getConfig('steps.userTypes', []).includes($store.state.application.user.type)">
                Étapes
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1 mr-2" to="/admin/reservations" text dark v-if="isUser">
                Réservations
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1 mr-2" to="/admin/intervenants" text dark v-if="isUser && !isSeller">
                Intervenants
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1 mr-2" to="/admin/rendez-vous" text dark v-if="isUser && !isSeller">
                Rendez-vous
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1 mr-2" to="/tma" text dark v-if="false && isBuyer">
                Choix & Options
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1 mr-2" to="/messages" text dark>
                <v-badge color="app-red" :content="unreadMessagesCount" :value="unreadMessagesCount">
                    Messagerie
                </v-badge>
            </v-btn>

            <v-btn class="text-none grey--text text--darken-1 mr-2" to="/actualites" text dark>
                Actualités
            </v-btn>
        </div>

        <UserProfile v-if="$store.state.application.isConnected" />
    </v-app-bar>
</template>

<script>
import SalesMenu from './SalesMenu';
import UserProfile from './UserProfile';

export default {
    name: 'AppBar',

    components: {
        SalesMenu,
        UserProfile
    },

    computed: {
        logoPath() {
            if (this.$store.state.application.user) {
                if (this.getConfig('messages.enabled', true)) {
                    return this.$store.state.application.user.type === 'buyer' ? '/' : '/messages';
                } else {
                    return this.$store.state.application.user.type === 'buyer' ? '/' : '/actualites';
                }
            }
            return '';
        },
        unreadMessagesCount() {
            const count = this.$store.state.application.unreadMessagesCount;
            if (typeof count === 'number' && !isNaN(count)) {
                if (count > 99) {
                    return '+99';
                } else {
                    return count;
                }
            } else {
                return 0;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.no-padding-left::v-deep {
    .v-toolbar__content {
        padding-left: 0;
    }
}
</style>