<template>
    <v-menu offset-y max-height="300px" v-if="selectedSale">
        <template v-slot:activator="{ on }">
            <v-btn class="text-capitalize grey--text text--darken-1 mr-2" text dark v-on="on">
                <template v-if="selectedSale">
                    <v-chip color="grey darken-1" label small>
                        {{ selectedSale.product.reference }} -
                        {{ selectedSale.product.typeLabel }}
                        {{ selectedSale.product.roomsLabel }}
                    </v-chip>
                </template>

                <v-icon right> fas fa-caret-down </v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-for="sale of $store.state.application.sales" :key="sale.id" @click="$store.commit('application/setSelectedSale', sale);">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ sale.product.reference }} -
                        {{ sale.product.typeLabel }}
                        {{ sale.product.roomsLabel }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        {{ sale.program.title }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'SalesMenu'
};
</script>