import Vue from 'vue';
import VueRouter from 'vue-router';

import authRepositry from './repositories/auth.js';
import store from './store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "Dashboard" */ './views/Dashboard.vue'),
            meta: { title: 'Accueil', authRequired: true }
        },
        {
            path: '/connexion',
            component: () => import(/* webpackChunkName: "LoginBuyer" */ './views/LoginBuyer.vue'),
            meta: { title: 'Connexion', authRequired: false }
        },
        {
            path: '/connexion/utilisateur',
            component: () => import(/* webpackChunkName: "LoginUser" */ './views/LoginUser.vue'),
            meta: { title: 'Connexion utilisateur', authRequired: false }
        },
        {
            path: '/suivi-de-chantier',
            component: () => import(/* webpackChunkName: "ProgressOnSite" */ './views/ProgressOnSite.vue'),
            meta: { title: 'Suivi de chantier', authRequired: true }
        },
        {
            path: '/etapes',
            component: () => import(/* webpackChunkName: "Steps" */ './views/Steps.vue'),
            meta: { title: 'Étapes', authRequired: true }
        },
        {
            path: '/documents',
            component: () => import(/* webpackChunkName: "Documents" */ './views/Documents.vue'),
            meta: { title: 'Documents', authRequired: true }
        },
        {
            path: '/actualites',
            component: () => import(/* webpackChunkName: "Actualities" */ './views/Actualities.vue'),
            meta: { title: 'Actualités', authRequired: true }

        },
        {
            path: '/appels-de-fonds',
            component: () => import(/* webpackChunkName: "CallForFunds" */ './views/CallForFunds.vue'),
            meta: { title: 'Appels de fonds', authRequired: true }
        },
        {
            path: '/encaissements',
            component: () => import(/* webpackChunkName: "CallForFunds" */ './views/CallForFunds.vue'),
            meta: { title: 'Suivi des encaissements', authRequired: true }
        },
        {
            path: '/tma',
            component: () => import(/* webpackChunkName: "Tma" */ './views/Tma.vue'),
            meta: { title: 'TMA', authRequired: true }
        },
        {
            path: '/messages',
            component: () => import(/* webpackChunkName: "Messages" */ './views/Messages.vue'),
            meta: { title: 'Messages', authRequired: true }
        },
        {
            path: '/admin/intervenants',
            component: () => import(/* webpackChunkName: "Intervenants" */ './views/Intervenants.vue'),
            meta: { title: 'Intervenants', authRequired: true }
        },
        {
            path: '/mentions-legales',
            component: () => import(/* webpackChunkName: "LegalNotice" */ './views/LegalNotice.vue'),
            meta: { title: 'Mentions légales', authRequired: true }
        },
        {
            path: '/admin/tma',
            component: () => import(/* webpackChunkName: "SOTAdmin" */ './views/SOTAdmin.vue'),
            meta: { title: 'TMA', authRequired: true }
        },
        {
            path: '/admin/reservations',
            component: () => import(/* webpackChunkName: "Sales" */ './views/admin/Sales.vue'),
            meta: { title: 'Réservations', authRequired: true }
        },
        {
            path: '/admin/rendez-vous',
            component: () => import(/* webpackChunkName: "AdminAppointments" */ './views/admin/Appointments.vue'),
            meta: { title: 'Rendez-vous', authRequired: true }
        },
        {
            path: '/reinitialisation-mot-de-passe',
            component: () => import(/* webpackChunkName: "ResetPassword" */ './views/ResetPassword.vue'),
            meta: { title: 'Réinitialisation de mot de passe', authRequired: false }
        },
        {
            path: '*',
            component: () => import(/* webpackChunkName: "NotFound" */ './views/NotFound.vue'),
            meta: { title: 'Page non trouvée', authRequired: false }
        }
    ]
});

router.beforeEach(async function (to, from, next) {
    document.title = `${to.meta.title} - Extranet client`;
    try {
        const query = {};
        const full = store.state.application.user === null;
        if (full) {
            setTimeout(() => {
                store.commit('application/setLoading', true);
            }, 0);
            query.full = 1;
        }
        const { isConnected, user, unreadMessagesCount, sales, actualities, config, err } = await authRepositry.getMe(query);
        if (user) {
            store.commit('application/setUser', user);
        }
        store.commit('application/setConfig', config);
        if (full) {
            store.commit('application/setLoading', false);
        }
        store.commit('application/setIsConnected', isConnected);

        if (to.meta.authRequired) {
            if (err) {
                throw new Error(err);
            }

            document.title = `${to.meta.title} - Extranet client ${config.name}`;
            if (isConnected) {
                if (Array.isArray(sales) && sales.length > 0) {
                    store.commit('application/setSales', sales);
                    store.commit('application/setActualities', actualities);
                    store.commit('application/setUnreadMessagesCount', unreadMessagesCount);
                    const saleId = parseInt(localStorage.getItem('selectedSale'));
                    if (saleId && !isNaN(saleId)) {
                        const sale = sales.find((s) => s.id === saleId);
                        if (sale) {
                            store.commit('application/setSelectedSale', sale);
                        } else {
                            store.commit('application/setSelectedSale', sales[0]);
                        }
                    } else {
                        store.commit('application/setSelectedSale', sales[0]);
                    }
                    next();
                } else {
                    next();
                }
            } else {
                let connexionPath = '/connexion';
                if (to.query.t === 'u') {
                    connexionPath = '/connexion/utilisateur';
                }
                if (to.path) {
                    next(connexionPath);
                } else {
                    next(`${connexionPath}?redirect=${to.path}&hash=${to.hash.slice(1)}`);
                }
            }
        } else {
            next();
        }
    } catch (err) {
        console.error(err);
        Vue.notify({
            type: 'error',
            title: 'Erreur',
            text: 'Une erreur est survenue durant le chargement de la page',
            duration: 10000
        });
    }
});

export default router;
