import productsRepository from '../repositories/products.js';

export default {
    namespaced: true,

    state: {
        types: [],
        rooms: [],
        statuses: [],
        fiscalites: []
    },

    mutations: {
        setTypes(state, types) {
            state.types = types.filter((r) => r.label);
            state.types.sort((a, b) => {
                if (a.label > b.label) { return 1; }
                else if (a.label < b.label) { return -1; }
                else { return 0; }
            });
        },
        setRooms(state, rooms) {
            state.rooms = rooms.filter((r) => r.label);
            state.rooms.sort((a, b) => {
                if (a.main > b.main) { return -1; }
                else if (a.main < b.main) { return 1; }
                else {
                    if (a.key > b.key) { return 1; }
                    else if (a.key < b.key) { return -1; }
                    else { return 0; }
                }
            });
        },
        setStatuses(state, statuses) {
            state.statuses = statuses;
        },
        setFiscalites(state, fiscalites) {
            state.fiscalites = fiscalites;
            state.fiscalites.sort((a, b) => {
                if (a.label > b.label) { return 1; }
                else if (a.label < b.label) { return -1; }
                else { return 0; }
            });
        }
    },

    actions: {
        async fetchProductsSummary(context) {
            try {
                const { types, rooms, statuses, fiscalites } = await productsRepository.getProductsSummary();
                context.commit('setTypes', types);
                context.commit('setRooms', rooms);
                context.commit('setStatuses', statuses);
                context.commit('setFiscalites', fiscalites);
            } catch (err) {
                console.error(err);
            }
        }
    }
};