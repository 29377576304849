import Repository from './Repository.js';

class ContactsRepository extends Repository {
    async getContacts(query) {
        return await this.get('contacts', query);
    }

    async getContact(contactId, query) {
        return await this.get(`contacts/${contactId}`, query);
    }

    async createContact(body) {
        return await this.post('contacts', body);
    }

    async sendSignUpNotification(body) {
        return await this.post('contacts/sendSignUpNotification', body);
    }
}

export default new ContactsRepository();
