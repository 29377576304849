<template>
    <v-dialog v-model="isOpen" width="60%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Connexions
                </v-toolbar-title>

                <v-select v-if="displayProviders" v-model="providerCode" :items="providerCodes" class="ml-4" placeholder="Provider Code" style="max-width: 220px" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined dense />

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-sheet class="pa-4" color="#eee">
                <v-row>
                    <v-col v-if="displayProviders" cols="6">
                        <v-sheet class="pa-2" color="white" rounded>
                            <h2 class="title primary--text mb-2"> Utilisateur Axessia </h2>

                            <v-text-field v-model="usersSearch" class="mb-2" placeholder="Recherche..." outlined dense hide-details />

                            <div style="position: relative;">
                                <v-overlay v-if="usersLoading" color="grey" absolute>
                                    <div class="d-flex justify-center">
                                        <v-progress-circular indeterminate size="64" />
                                    </div>
                                </v-overlay>

                                <v-list style="max-height: 310px; overflow-y: auto;">
                                    <v-list-item v-for="user of displayUsers" :key="user.id" @click="createSession('user', user)" class="mr-2">
                                        <v-list-item-avatar>
                                            <v-icon color="light-blue darken-2" dark> fas fa-user </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ user.name }} {{ user.firstname }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                {{ user.email }}
                                                <template v-if="user.targetType === 0">
                                                    Admin
                                                </template>
                                                <template v-else-if="user.targetType === 1">
                                                    Secteur
                                                </template>
                                                <template v-else>
                                                    Vendeur
                                                </template>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-sheet>
                    </v-col>

                    <v-col :cols="!displayProviders ? 12 : 6">
                        <v-sheet class="pa-2" color="white" rounded>
                            <h2 class="title primary--text mb-2">
                                Acquéreurs
                            </h2>

                            <v-text-field v-model="buyersSearch" class="mb-2" placeholder="Recherche..." outlined dense hide-details />

                            <ProgramAutocomplete ref="programAutocomplete" v-model="buyersProgramsSearch" multiple noDynamicSearch closeOnContentClick label="Programmes" class="mb-2" />

                            <div style="position: relative;">
                                <v-overlay v-if="buyersLoading" color="grey" absolute>
                                    <div class="d-flex justify-center">
                                        <v-progress-circular indeterminate size="64" />
                                    </div>
                                </v-overlay>

                                <v-list style="height: 310px; overflow-y: auto;">
                                    <v-list-item v-for="buyer of displayBuyers" :key="buyer.uid" @click="createSession('buyer', buyer)" class="mr-2">
                                        <v-list-item-avatar>
                                            <v-icon color="light-blue darken-2" dark> fas fa-user-tie </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ buyer.name }} {{ buyer.firstname }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <v-chip v-for="program of buyer.programs" :key="buyer.email + '_' + program.title" class="mr-1" small label>
                                                    {{ program.title }} -
                                                    <template v-for="product of program.products">
                                                        <span :key=" buyer.email + '_' + program.title + '_' + product.reference" class="ml-1"> {{ product.reference }}</span>
                                                    </template>
                                                </v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" depressed small> Fermer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ProgramAutocomplete from '../../components/widgets/ProgramAutocomplete.vue';

export default {
    name: 'DebugAccounts',

    components: {
        ProgramAutocomplete
    },

    data: () => ({
        isOpen: false,
        isLoading: false,
        users: [],
        displayUsers: [],
        buyers: [],
        displayBuyers: [],
        providerCodes: [],
        providerCode: null,

        usersLoading: false,
        usersSearch: null,
        usersSearchTimeout: null,
        buyersLoading: false,
        buyersSearch: null,
        buyersSearchTimeout: null,
        buyersProgramsSearch: [],

        additionalDataFecthed: false
    }),

    computed: {
        displayProviders() {
            return location.host === 'localhost:8080' || (this.$store.state.application.user && this.$store.state.application.user.isDP2I);
        }
    },

    methods: {
        async createSession(type, account) {
            try {
                this.setLoading(true);

                const body = {
                    type,
                    account,
                    providerCode: this.providerCode
                };

                this.$store.commit('application/setProviderCode', this.providerCode);
                const { success, err } = await this.repos.debug.createSession(body);
                if (success) {
                    switch (type) {
                        case 'buyer':
                            location.href = '/';
                            break;
                        case 'user':
                            location.href = '/messages';
                            break;
                    }

                    this.isOpen = false;
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de la session',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchProviders() {
            try {
                const { providers } = await this.repos.providers.getAvailables();
                this.providerCodes = providers;
            } catch (err) {
                console.error(err);
            }
        },

        async fetchUsersAccounts() {
            try {
                this.usersLoading = true;

                const query = {
                    providerCode: this.providerCode,
                    limit: 5
                };
                if (this.usersSearch) {
                    query.quickSearch = this.usersSearch;
                }
                const { users, err } = await this.repos.debug.getUserAccounts(query);
                if (err) {
                    throw new Error();
                } else {
                    this.users = users;
                    this.displayUsers = this.users;
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la récupération des comptes utilisateurs',
                    type: 'error'
                });
            } finally {
                this.usersLoading = false;
            }
        },

        async fetchBuyerAccounts() {
            try {
                this.buyersLoading = true;

                const query = {
                    providerCode: this.providerCode,
                    limit: 100
                };
                if (this.buyersSearch) {
                    query.quickSearch = this.buyersSearch;
                }
                if (this.buyersProgramsSearch && this.buyersProgramsSearch.length > 0) {
                    query.programs = this.buyersProgramsSearch.join(',');
                }
                const { contacts, err } = await this.repos.contacts.getContacts(query);
                if (err) {
                    throw new Error();
                } else {
                    this.buyers = contacts;
                    this.displayBuyers = this.buyers;
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la récupération des comptes acquéreurs',
                    type: 'error'
                });
            } finally {
                this.buyersLoading = false;
            }
        },

        makeBuyersSearch() {
            this.displayBuyers = this.buyers;

            if (this.buyersSearch) {
                const search = this.buyersSearch.trim().toLowerCase();
                this.displayBuyers = this.buyers.filter((buyer) => {
                    return (buyer.name && buyer.name.toLowerCase().includes(search))
                        || (buyer.firstname && buyer.firstname.toLowerCase().includes(search));
                });
            }

            if (this.buyersProgramsSearch.length > 0) {
                this.displayBuyers = this.displayBuyers.filter((buyer) => {
                    return buyer.programs.find((p) => this.buyersProgramsSearch.includes(p.id));
                });
            }
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.providerCode = this.$store.state.application.providerCode;
                this.fetchProviders();
                if (!this.additionalDataFecthed) {
                    this.additionalDataFecthed = true;
                    this.$nextTick(() => {
                        this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
                    });
                }
            }
        },

        providerCode() {
            this.fetchBuyerAccounts();
            this.fetchUsersAccounts();
        },

        usersSearch() {
            clearTimeout(this.usersSearchTimeout);
            this.usersSearchTimeout = setTimeout(() => {
                this.fetchUsersAccounts();
            }, 500);
        },

        buyersSearch() {
            clearTimeout(this.partnersSearchTimeout);
            this.partnersSearchTimeout = setTimeout(() => {
                this.fetchBuyerAccounts();
                // this.makeBuyersSearch();
            }, 500);
        },

        buyersProgramsSearch() {
            document.body.focus();
            this.fetchBuyerAccounts();
            // this.makeBuyersSearch();
        }
    },

    created() {
        window.addEventListener('keyup', (e) => {
            if (e.altKey && e.ctrlKey && e.key === 'a') {
                this.isOpen = true;
            }
        });
    }
};
</script>